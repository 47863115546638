import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`activity-branches?${params}`)
  },

  async get (id) {
    return await axios.get(`activity-branches/${id}`)
  },

  async insert (data) {
    return await axios.post('activity-branches', data)
  },

  async update (id, data) {
    return await axios.put(`activity-branches/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`activity-branches/${id}`)
  },

}
