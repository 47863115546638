<template>
  <v-row class="mt-4">
    <v-col>
      <v-card
        :loading="loadingGet"
      >
        <v-toolbar
          color="info"
          flat
          dark
          dense
        >
          <v-toolbar-title class="white--text font-weight-black">
            # {{ customer.id || '--' }}
          </v-toolbar-title>

          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              align-with-title
            >
              <v-tabs-slider color="primary" />
              <v-tab
                key="Dados"
              >
                Dados
              </v-tab>
              <v-tab
                key="Contatos"
                :disabled="!customer.id"
              >
                Contatos
              </v-tab>
              <v-tab
                key="Contrato"
                :disabled="!customer.id"
              >
                Contrato
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider />

        <v-tabs-items v-model="tab">
          <v-tab-item key="Dados">
            <validation-observer ref="observer">
              <v-form class="mt-4">
                <v-container class="">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        vid="companyName"
                      >
                        <v-text-field
                          v-model="newCustomer.companyName"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="Razão Social"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        vid="nickname"
                      >
                        <v-text-field
                          v-model="newCustomer.nickname"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="Nome Fantasia"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="3"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        vid="cnpj"
                      >
                        <v-text-field
                          v-model="newCustomer.cnpj"
                          v-mask="'##.###.###/####-##'"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="CNPJ"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="3">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="activityBranchId"
                      >
                        <v-select
                          v-model="newCustomer.activityBranchId"
                          :value="newCustomer.activityBranchId"
                          :items="activityBranches"
                          item-text="description"
                          item-value="id"
                          label="Ramo de Atividade"
                          hide-details="auto"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        vid="contractNumber"
                      >
                        <v-text-field
                          v-model="newCustomer.contractNumber"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="Número do contrato"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row class="mt-5">
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="street"
                      >
                        <v-text-field
                          v-model="newCustomer.address.street"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="Rua"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="2">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="number"
                      >
                        <v-text-field
                          v-model="newCustomer.address.number"
                          v-mask="'######'"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="Número"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="4">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="district"
                      >
                        <v-text-field
                          v-model="newCustomer.address.district"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="Bairro"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="3">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="cep"
                      >
                        <v-text-field
                          v-model="newCustomer.address.cep"
                          v-mask="'#####-###'"
                          :disabled="loadingGet"
                          hide-details="auto"
                          label="CEP"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="3">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="stateId"
                      >
                        <autocomplete-states
                          v-model="stateId"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="3">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="stateId"
                      >
                        <autocomplete-cities
                          v-model="newCustomer.address.cityId"
                          :state-id="stateId"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-divider class="mt-4" />
            <v-row>
              <v-col
                cols="12"
                class="pa-5 text-left"
              >
                <btn-save
                  :loading="loadingSave"
                  @click="save"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            key="Contatos"
            class="pa-5"
          >
            <contacts
              :key="customer.id"
              :customer="customer"
            />
          </v-tab-item>
          <v-tab-item
            key="Contrato"
          >
            <contract
              :key="customer.id"
              :customer="customer"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import _ from 'lodash'
  import activityBranchesApi from '@/api/activity-branches'

  export default {

    components: {
      AutocompleteStates: () => import('@/components/input/InputAutocompleteStates'),
      AutocompleteCities: () => import('@/components/input/InputAutocompleteCities'),
      Contacts: () => import('@/views/pages/customers/CustomersContacts'),
      Contract: () => import('@/views/pages/customers/CustomersContract'),
    },

    props: {
      formValidation: {
        type: Object,
        default: () => { return {} },
      },
      loadingSave: {
        type: Boolean,
        default: false,
      },
      loadingGet: {
        type: Boolean,
        default: false,
      },
      loadingDeleteContact: {
        type: Boolean,
        default: false,
      },
      customer: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        tab: null,
        activityBranches: [],
        stateId: null,
        dialogDeleteContact: false,
        deleteContact: null,
        newCustomer: {
          companyName: null,
          cnpj: null,
          activityBranchId: null,
          address: {
            street: null,
            number: null,
            district: null,
            cep: null,
            cityId: null,
          },
        },
      }
    },

    computed: {
      edit () {
        return !_.isEmpty(this.customer)
      },
    },

    watch: {
      formValidation (val) {
        if (_.isEmpty(val)) return false

        return this.$refs.observer.setErrors(val)
      },

      customer (val) {
        if (_.isEmpty(val)) return

        if (val.address) {
          this.stateId = val.address.city ? val.address.city.stateId : null
        } else {
          val.address = {}
        }

        this.newCustomer = val
      },
    },

    mounted () {
      this.getActivityBranches()
    },

    methods: {
      save () {
        this.$refs.observer.reset()
        this.$emit('save', this.newCustomer)
      },

      async getActivityBranches () {
        try {
          const response = await activityBranchesApi.list()

          this.activityBranches = response.data.branches
        } catch (e) {
          console.log(e)
        }
      },

      openDialogDeleteContact (contact) {
        this.deleteContact = contact
        this.dialogDeleteContact = true
      },

      confirmDeleteContact () {
        this.$emit('delete-contact', this.deleteContact)
        this.dialogDeleteContact = false
      },

    },

  }
</script>
