var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-card',{attrs:{"loading":_vm.loadingGet}},[_c('v-toolbar',{attrs:{"color":"info","flat":"","dark":"","dense":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{key:"Dados"},[_vm._v(" Dados ")]),_c('v-tab',{key:"Contatos",attrs:{"disabled":!_vm.customer.id}},[_vm._v(" Contatos ")]),_c('v-tab',{key:"Contrato",attrs:{"disabled":!_vm.customer.id}},[_vm._v(" Contrato ")])],1)]},proxy:true}])},[_c('v-toolbar-title',{staticClass:"white--text font-weight-black"},[_vm._v(" # "+_vm._s(_vm.customer.id || '--')+" ")])],1),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"Dados"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-4"},[_c('v-container',{},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"companyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Razão Social","error-messages":errors},model:{value:(_vm.newCustomer.companyName),callback:function ($$v) {_vm.$set(_vm.newCustomer, "companyName", $$v)},expression:"newCustomer.companyName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"nickname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Nome Fantasia","error-messages":errors},model:{value:(_vm.newCustomer.nickname),callback:function ($$v) {_vm.$set(_vm.newCustomer, "nickname", $$v)},expression:"newCustomer.nickname"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"CNPJ","error-messages":errors},model:{value:(_vm.newCustomer.cnpj),callback:function ($$v) {_vm.$set(_vm.newCustomer, "cnpj", $$v)},expression:"newCustomer.cnpj"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"activityBranchId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"value":_vm.newCustomer.activityBranchId,"items":_vm.activityBranches,"item-text":"description","item-value":"id","label":"Ramo de Atividade","hide-details":"auto","error-messages":errors},model:{value:(_vm.newCustomer.activityBranchId),callback:function ($$v) {_vm.$set(_vm.newCustomer, "activityBranchId", $$v)},expression:"newCustomer.activityBranchId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"contractNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Número do contrato","error-messages":errors},model:{value:(_vm.newCustomer.contractNumber),callback:function ($$v) {_vm.$set(_vm.newCustomer, "contractNumber", $$v)},expression:"newCustomer.contractNumber"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Rua","error-messages":errors},model:{value:(_vm.newCustomer.address.street),callback:function ($$v) {_vm.$set(_vm.newCustomer.address, "street", $$v)},expression:"newCustomer.address.street"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"vid":"number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Número","error-messages":errors},model:{value:(_vm.newCustomer.address.number),callback:function ($$v) {_vm.$set(_vm.newCustomer.address, "number", $$v)},expression:"newCustomer.address.number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"district"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Bairro","error-messages":errors},model:{value:(_vm.newCustomer.address.district),callback:function ($$v) {_vm.$set(_vm.newCustomer.address, "district", $$v)},expression:"newCustomer.address.district"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"cep"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"CEP","error-messages":errors},model:{value:(_vm.newCustomer.address.cep),callback:function ($$v) {_vm.$set(_vm.newCustomer.address, "cep", $$v)},expression:"newCustomer.address.cep"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"stateId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete-states',{attrs:{"error-messages":errors},model:{value:(_vm.stateId),callback:function ($$v) {_vm.stateId=$$v},expression:"stateId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"stateId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete-cities',{attrs:{"state-id":_vm.stateId,"error-messages":errors},model:{value:(_vm.newCustomer.address.cityId),callback:function ($$v) {_vm.$set(_vm.newCustomer.address, "cityId", $$v)},expression:"newCustomer.address.cityId"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-row',[_c('v-col',{staticClass:"pa-5 text-left",attrs:{"cols":"12"}},[_c('btn-save',{attrs:{"loading":_vm.loadingSave},on:{"click":_vm.save}})],1)],1)],1),_c('v-tab-item',{key:"Contatos",staticClass:"pa-5"},[_c('contacts',{key:_vm.customer.id,attrs:{"customer":_vm.customer}})],1),_c('v-tab-item',{key:"Contrato"},[_c('contract',{key:_vm.customer.id,attrs:{"customer":_vm.customer}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }